import 'core-js/es/promise';

//@ts-ignore
if (window.fetch) {
	console.log('FETCH IS DEFINED, modern browser')
	import('./app').then((module: any) => module.default());
} else {
	console.log('FETCH IS [NOT] DEFINED, outdated browser')
	import('@src/tools/Helpers/Tools/ToolsBox/Polyfills').then(() => {
		import('./app').then((module: any) => module.default());
	})
}